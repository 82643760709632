import {
  chats,
  studioAccount,
  computer,
  VX,
  desktopAndMobile,
} from '../../../../atoms/Icon/libraries/svg';
import {
  asterisk,
  bullhorn,
  earphone,
  film,
  group,
  listAlt,
  money,
  questionSign,
  webcam,
} from '../../../../atoms/Icon/libraries/glyph';
import { BLACK, BLUE, GREEN, RED, YELLOW } from '../../../../camtool-styles';

interface IMappingAttributes {
  icon: string;
  color?: string;
  svg?: boolean;
}

interface IMapping {
  [key: string]: IMappingAttributes;
}

export const iconMapping: IMapping = {
  // chats
  '360002382800': { icon: chats, svg: true }, // vxmodels
  // first steps
  '360002285439': { icon: asterisk, color: `${BLUE}` }, // vxmodels
  // pictures and videos
  '360002285499': { icon: film, color: `${BLUE}` }, // vxmodels
  // Softare, Messenger, Splitter and Technical
  '360002286979': { icon: webcam, color: `${YELLOW}` }, // vxmodels
  // general information
  '360002263760': { icon: questionSign, color: `${BLUE}` }, // vxmodels
  // our products
  '360002286959': {
    icon: desktopAndMobile,
    svg: true,
  }, // hardware recommendation
  '360003025499': { icon: computer, svg: true },
  // vxmodels
  '360001679099': {
    icon: VX,
    svg: true,
  },
  // studio account
  '360002685320': {
    icon: studioAccount,
    svg: true,
  }, // vxmodels
  // marketing
  '360002305039': { icon: bullhorn, color: `${RED}` }, // vxmodels
  // FAQ
  '360002263780': { icon: listAlt, color: `${BLACK}` }, // vxmodels
  // finances
  '360002260060': { icon: money, color: `${GREEN}` }, // vxmodels
  // Hausordnung und Chat fairplay
  '360002371339': { icon: group, color: `${BLACK}` }, // vxmodels
  // Phone service
  '360002584379': { icon: earphone, color: BLACK }, // vxmodels
};
