import React, { FC, Fragment } from 'react';
import Media from 'react-media';
import { ClassNames } from '@emotion/core';
import { Markdown } from '../../components/';
import { Main, Section } from '../../atoms';
import { useAppState } from '../../util/AppState';
import { ApiLang } from '../../graphql/VXModels/types';
import TableRow, { RowType } from './components/TableRow';
import GetOnlineCard from './GetOnlineCard';
import vxLiveScreen from './img/vxliveScreenSmall.png';
import mia from './img/mia.png';
import {
  NEW_MESSENGER_URL,
  NEW_MESSENGER_WINDOW_NAME,
  VXSPLITTER_HELPCENTER_VXMODELS,
} from '../../config';
import { _ } from '../../util/translate';
import { vxLiveLogo } from '../../atoms/Icon/libraries/svg';
import NewButton from '../../atoms/Button/NewButton';
import { Box, Typography } from '@material-ui/core';
import { useUserData } from '../../util/UserData';

const GetOnline: FC = () => {
  const [{ authToken, lang }] = useAppState();
  const userData = useUserData();
  const isVerified = userData?.model?.account.isVerified;

  return (
    <Main>
      <Section title={_('getOnline:section.camAndChat')}>
        <div css={{ flexWrap: 'wrap', justifyContent: 'center' }}>
          <GetOnlineCard
            safari
            edge
            chrome
            firefox
            webcam
            logo={vxLiveLogo}
            image={vxLiveScreen}
            note={_('getOnline:note.browserVersion')}
          >
            <h2 css={{ fontSize: 24 }}>{_('getOnline:vXLive.onlineCard.title')}</h2>
            <ul
              css={{
                columns: 2,
                listStylePosition: 'inside',
                marginTop: '20px',
                fontSize: '18px',
              }}
            >
              <li>{_('getOnline:vXLive.onlineCard.advantages.firstArgument')}</li>
              <li>{_('getOnline:vXLive.onlineCard.advantages.secondArgument')}</li>
              <li>{_('getOnline:vXLive.onlineCard.advantages.thirdArgument')}</li>
              <li>{_('getOnline:vXLive.onlineCard.advantages.fourthArgument')}</li>
              <li>{_('getOnline:vXLive.onlineCard.advantages.fifthArgument')}</li>
              <li>{_('getOnline:vXLive.onlineCard.advantages.sixthArgument')}</li>
            </ul>
            <Box mt={4} alignItems="center" justifyContent="center" width="100%">
              <NewButton
                color="secondary"
                disabled={!isVerified}
                onClick={(): Window | null =>
                  window.open(
                    `${NEW_MESSENGER_URL}?foreignSID=${authToken}`,
                    `${NEW_MESSENGER_WINDOW_NAME}`
                  )
                }
              >
                <span>{_('getOnline:vXLive.onlineCard.button.start')}</span>
              </NewButton>
              {!isVerified && <Typography>{_('common:text.notVerified')}</Typography>}
            </Box>
          </GetOnlineCard>
        </div>
      </Section>

      <Fragment>
        <Section css={{ marginTop: 32 }}>
          <div
            css={{
              margin: '0 48px',
              alignItems: 'flex-start',
              '@media (max-width: 1200px)': {
                flexWrap: 'wrap',
                justifyContent: 'center',
                margin: '58px 48px',
              },
            }}
          >
            <Media query="(max-width: 1200px)">
              {(matches) => {
                let width = 400;
                let margin = '10px 48px 0 0';
                if (matches) {
                  width = 561;
                  margin = '0';
                }

                return (
                  <div css={{ width, height: '190px', margin }}>
                    <iframe
                      title="Chat-Fair-Play Rules"
                      src={`https://player.vimeo.com/video/${
                        lang === ApiLang.de ? 337777752 : 337955207
                      }`}
                      width={width}
                      height={width * (360 / 640)}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </div>
                );
              }}
            </Media>

            <div
              css={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: '1 0 auto',
                margin: '24px 0',
              }}
            >
              <h3 css={{ fontSize: 16 }}>{_('getOnline:fairPlayChat.title')}</h3>

              <Markdown
                source={_('getOnline:fairPlayChat.description')}
                css={{ margin: '8px 0', p: { padding: '4px 0', ul: { padding: 12 } } }}
              />
            </div>

            <figure css={{ '@media (max-width: 1500px)': { display: 'none' } }}>
              <img src={mia} alt="" css={{ maxHeight: 240 }} />
            </figure>
          </div>
        </Section>

        <ClassNames>
          {({ css: classNamesCss }) => (
            <Section
              title={_('getOnline:section.oldSoftware')}
              css={{ marginTop: 32 }}
              contentContanierClass={classNamesCss({ padding: 32 })}
            >
              <TableRow
                type={RowType.helpcenter}
                title={_('getOnline:title.vXSplitter')}
                description={_('getOnline:description.vXSplitter')}
                link={VXSPLITTER_HELPCENTER_VXMODELS}
              />
            </Section>
          )}
        </ClassNames>
      </Fragment>
    </Main>
  );
};

export default GetOnline;
