import React, { useEffect, useRef, useState } from 'react';
import { Query } from '@apollo/react-components';
import styled from '@emotion/styled';

import { Button, ButtonLink } from '../../../atoms';
import { T } from '../../../components';
import { APP_BASE_PATH, APP_IS_DEVELOPMENT } from '../../../util/env';
import { publish } from '../../../util/PubSub';
import { BLACK, WHITE, WHITE_3, GRAY_2 } from '../../../camtool-styles';

import { StyledButton } from '../styles';

import ProfileLanguageSelector from './ProfileLanguageSelector';
import { SecurityRole, VXModelsMobileTargetEnum } from '../../../graphql/VXModels/types';
import securityRoleColors from '../../../util/securityRoleColors';
import { hasRole } from '../../../util/UserData';
import { QUERY_AUTH_MODEL_GENDER_AVATAR } from '../../../graphql/VXModels/queries';
import { _ } from '../../../util/translate';
import { BREAKPOINT_SHRINK_PROFILE } from '../constants';
import { Box, Grid } from '@material-ui/core';
import { useDeepLinkBeta } from '../../../hooks';
import { useLang } from '../../../util/AppState';

const MEDIA_QUERY_SHRINK_PROFILE = `@media (max-width: ${BREAKPOINT_SHRINK_PROFILE}px)`;

const AvatarContainer = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 2px solid ${WHITE};
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const ButtonLabel = styled.p`
  margin-left: 8px;
  font-size: 16px;
  white-space: nowrap;

  ${MEDIA_QUERY_SHRINK_PROFILE} {
    display: none;
  }
`;

const ProfileButton = styled(StyledButton)`
  margin: 0 16px;

  ${MEDIA_QUERY_SHRINK_PROFILE} {
    margin: 0;
  }
`;

const PopupContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 49px;
  flex-direction: column;
  padding: 24px 42px;
  align-items: center;
  text-align: center;
  background-color: ${WHITE};
  color: ${BLACK};
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;

  ${MEDIA_QUERY_SHRINK_PROFILE} {
    right: 0;
  }
`;

const Profile = () => {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('click', handlePageClick);
    return () => {
      window.removeEventListener('click', handlePageClick);
    };
  }, []);

  const [lang] = useLang();

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  const logOut = () => publish('session.close', {});

  const handlePageClick = (event) => {
    // If the button is clicked, we let the button handle stuff. Otherwise, we handle it.
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return null;
    } else {
      // !!containerRef.current means whether isOpen is true or false. If it
      // is open but the user clicked outside it, then we set isOpen to false.
      // otherwise we do nothing
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        close();
      }
    }
  };

  const {
    data: betaDeepLinkData,
    loading: betaDeepLinkLoading,
    error: betaDeepLinkError,
    refetch,
  } = useDeepLinkBeta(VXModelsMobileTargetEnum.dashboard);

  const goToBeta = () => {
    refetch();
    const dashboardLink = betaDeepLinkData?.auth?.betaDeeplink || '';
    window.open(dashboardLink, '_blank');
  };

  const isDeepLinkReady = betaDeepLinkData && !betaDeepLinkLoading && !betaDeepLinkError;

  return (
    <Query query={QUERY_AUTH_MODEL_GENDER_AVATAR}>
      {({ data, loading, error }) => {
        if (error) {
          console.error(error);
          return null;
        }
        const { id, hotlineCode, roles, screenname, username } = data?.auth || { id: '', hotlineCode: '', roles: [], screenname: '', username: '' }; // prettier-ignore
        const { avatar } = data?.model || { avatar: { picture: { url: '' } } };
        const { profile } = data?.model || { profile: { gender: { value: '' } } };
        const { account } = data?.model || {
          account: { keyAccounter: { contactEmail: '', contactName: '', contactPhoneNumber: '' } },
        };
        const mailToString =
          lang === 'de'
            ? `mailto:${account?.keyAccounter?.contactEmail}?subject=Bitte%20Zurückrufen%20Modelid:%20${id}%20Modelname:%20${username}`
            : `mailto:${account?.keyAccounter?.contactEmail}?subject=Please%20call%20back%20Modelid:%20${id}%20Modelname:%20${username}`;
        const showRoles = APP_IS_DEVELOPMENT || hasRole(SecurityRole.VX_USER_ADMIN);

        return (
          <div style={{ position: 'relative' }}>
            <ProfileButton ref={buttonRef} onClick={isOpen ? close : open} isOpen={isOpen}>
              {loading || !avatar.picture || !avatar.picture.url ? (
                <AvatarContainer>
                  <span className={`icon-${profile.gender.value === 'male' ? 'user' : 'woman'}`} />
                </AvatarContainer>
              ) : (
                <img
                  src={avatar.picture.url}
                  style={{
                    border: `2px solid ${WHITE}`,
                    borderRadius: '50%',
                    width: 32,
                    height: 32,
                  }}
                  alt=""
                />
              )}
              <ButtonLabel>
                <T _="navigation:main.myAccount" />
              </ButtonLabel>
            </ProfileButton>

            {isOpen && (
              <PopupContainer ref={containerRef}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: showRoles ? 'space-between' : 'center',
                    width: '100%',
                    alignItems: 'baseline',
                    marginBottom: 30,
                  }}
                >
                  {showRoles && (
                    <span style={{ marginRight: 30 }}>
                      {roles.map((role, idx) => (
                        <span
                          key={idx}
                          title={role}
                          className={'icon-user'}
                          style={{ color: securityRoleColors[role] }}
                        >
                          {' '}
                        </span>
                      ))}
                    </span>
                  )}

                  <ProfileLanguageSelector />
                </Box>

                <h2 style={{ whiteSpace: 'nowrap', marginBottom: 8 }}>{screenname}</h2>

                <ul style={{ listStyle: 'none', whiteSpace: 'nowrap' }}>
                  <li style={{ marginBottom: 4 }}>
                    <T _="common:modelid.label" />: {id}
                  </li>
                  {hasRole(SecurityRole.VX_USER_MASTER) && <li>Hotline Code: {hotlineCode}</li>}
                </ul>

                {!!account && !!account?.keyAccounter?.contactName && !loading && (
                  <Grid
                    container
                    spacing={1}
                    style={{
                      justifyContent: 'center',
                      textAlign: 'left',
                      minWidth: '25rem',
                      marginTop: '30px',
                      marginBottom: '10px',
                      backgroundColor: WHITE_3,
                      color: GRAY_2,
                      padding: '5px',
                    }}
                  >
                    <Grid container item xs={12} alignItems={'baseline'}>
                      <Grid item xs={6}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <T _="common:keyaccounter.name" />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          {account?.keyAccounter?.contactName}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems={'baseline'}>
                      <Grid item xs={6}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <T _="common:keyaccounter.email" />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <a
                            href={`mailto:${account?.keyAccounter?.contactEmail}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              theme="link-blue"
                              css={{ textDecoration: 'none', fontSize: '1em' }}
                            >
                              {account?.keyAccounter?.contactEmail}
                            </Button>
                          </a>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems={'center'}>
                      <Grid item xs={6}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <T _="common:keyaccounter.phone" />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <a
                            href={'tel:' + account?.keyAccounter?.contactPhoneNumber}
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              theme="link-blue"
                              css={{ textDecoration: 'none', fontSize: '1em' }}
                            >
                              {account?.keyAccounter?.contactPhoneNumber}
                            </Button>
                          </a>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <ButtonLink
                  to={`${APP_BASE_PATH}/account`}
                  onClick={close}
                  theme="blue"
                  label={_('navigation:main.account')}
                  css={{ marginTop: 24, whiteSpace: 'nowrap' }}
                />

                <Button
                  theme="link-gray"
                  css={{ textDecoration: 'none', marginTop: 16 }}
                  onClick={logOut}
                >
                  <span className="icon-power" style={{ marginRight: 8 }} />
                  <span>
                    <T _="navigation:main.logout" />
                  </span>
                </Button>

                <Button
                  theme="link-blue"
                  css={{ textDecoration: 'none', marginTop: 30, fontsize: '1.4em' }}
                  disabled={!isDeepLinkReady}
                  onClick={goToBeta}
                >
                  <span>{_('dashboard:app.switchVXMBeta.button')} ↗</span>
                </Button>
              </PopupContainer>
            )}
          </div>
        );
      }}
    </Query>
  );
};

export default Profile;
