import React, { Component, Fragment } from 'react';
import { ClassNames } from '@emotion/core';
import * as Formsy from 'formsy-react';

import { AccountStore } from '../../../stores/Account/AccountStore';
import { AccountActionCreators } from '../../../stores/Account/AccountActionCreators';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';
import { getFormattedDate } from '../../../util/Formatter';
import { hasRole, UserDataContext } from '../../../util/UserData';
import {
  Button,
  ButtonGroup,
  EmptyContent,
  Form,
  GroupColumn,
  GroupColumnNew,
  GroupRow,
  Markdown,
  Notice,
  Spinner,
  T,
  VerifyButton,
} from '../../../components';
import Dropdown from '../../../components/Form/SingleOption/Dropdown/Dropdown';
import Inputfield from '../../../components/Form/Text/Inputfield/Inputfield';
import ModalPhoneConfirmation from '../../../components/Modalboxes/ModalPhoneConfirmation/ModalPhoneConfirmation';
import { SecurityRole } from '../../../graphql/VXModels/types';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';

const STORE = 'personaldata';

function getStateFromStores() {
  return {
    storeData: AccountStore.get(STORE),
    hasChange: false,
  };
}

class PersonalData extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);
    this.state = {
      ...getStateFromStores(),
      errorInputNames: [],
      displayErrorInputs: {},
      displayErrors: false,
    };

    this.loadingDone = false;

    this.onStoreChange = this.onStoreChange.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onContactFormSubmit = this.onContactFormSubmit.bind(this);
    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.changeDisability = this.changeDisability.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.saveMobilePhone = this.saveMobilePhone.bind(this);
    this.checkVerificationCode = this.checkVerificationCode.bind(this);
    this.requestVerificationCode = this.requestVerificationCode.bind(this);
    this.getTranslationForFormField = this.getTranslationForFormField.bind(this);
    this.getAccountBirthdate = this.getAccountBirthdate.bind(this);
    this.userPrivileged = this.userPrivileged.bind(this);
  }

  componentDidMount() {
    AccountStore.addChangeListener(STORE, this.onStoreChange);
    AccountActionCreators.getPersonalData();
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(STORE, this.onStoreChange);
  }

  onStoreChange() {
    this.loadingDone = true;
    this.setState(getStateFromStores());

    // ALERTS
    // CHECK EMAIL
    if (this.state.storeData.email.errorMessage) {
      if (this.state.storeData.email.errorMessage === 'success') {
        AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
      } else {
        // eslint-disable-next-line react/no-string-refs
        this.refs.emailPhoneForm.updateInputsWithError({
          email: this.state.storeData.email.errorMessage,
        });
        AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
      }
    }
    if (this.state.storeData.address.savingResult.savingStatus === 'ok') {
      AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
    } else if (this.state.storeData.address.savingResult.savingStatus === 'fail') {
      AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
      const invalidFields = this.state.storeData.address.savingResult.invalidFields;
      const errors = {};
      if (invalidFields.length > 0) {
        for (let i = 0; i < invalidFields.length; i++) {
          errors[invalidFields[i]] = this.getTranslationForFormField(invalidFields[i]);
        }
        // eslint-disable-next-line react/no-string-refs
        this.refs.addressForm.updateInputsWithError(errors);
      }
    }
  }

  onValidSubmit(model) {
    this.setState({ displayErrors: false });
    AccountActionCreators.savePersonalData(model);
  }

  onContactFormSubmit(model) {
    AccountActionCreators.saveContactData(model);
  }

  onInvalidSubmit(model) {
    // eslint-disable-next-line react/no-string-refs
    const errorInputNames = this.refs.addressForm.getErrorInputNames();
    const nextState = {
      errorInputNames: errorInputNames,
      // eslint-disable-next-line react/no-string-refs
      displayErrorInputs: this.refs.addressForm.getDisplayErrorInputs(errorInputNames),
    };
    if (this.state.displayErrors === false) {
      nextState.displayErrors = true;
    }
    this.setState(nextState);
    AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
  }

  onFormChange(model, formChanged) {
    this.setState({ hasChanges: formChanged });
  }

  changeDisability(verificationMode, disabilityStatus) {
    const state = getStateFromStores();
    const { storeData } = state;

    switch (verificationMode) {
      case state.storeData.email.verificationMode:
        storeData.email.disabled = disabilityStatus;
        storeData.email.verificationStatus = 'new';
        break;
      case state.storeData.fixedPhone.verificationMode:
        storeData.fixedPhone.disabled = disabilityStatus;
        storeData.fixedPhone.verificationStatus = 'new';
        break;
      case state.storeData.mobilePhone.verificationMode:
        storeData.mobilePhone.disabled = disabilityStatus;
        storeData.mobilePhone.verificationStatus = 'new';
        break;
      default:
    }

    this.setState({ storeData });
  }

  saveValue(verificationMode) {
    let newValue = '';
    if (this.state.storeData.email.verificationMode === verificationMode) {
      // eslint-disable-next-line react/no-string-refs
      newValue = this.refs.emailAddress.getValue();
    }
    //CHECK EMAIL ON CHANGE
    if (
      this.state.storeData.isEmailChanged &&
      newValue !== this.state.storeData.hostActualEmailAddress
    ) {
      AlertsStore.add({ type: 'info', message: _('common:alerts.emailSendingSuccess') });
    }
    if (this.state.storeData.fixedPhone.verificationMode === verificationMode) {
      // eslint-disable-next-line react/no-string-refs
      newValue = this.refs.fixedPhoneNumber.getValue();
    }
    if (this.state.storeData.mobilePhone.verificationMode === verificationMode) {
      // eslint-disable-next-line react/no-string-refs
      newValue = this.refs.mobilePhoneNumber.getValue();
    }
    AccountActionCreators.saveEmailOrPhone(verificationMode, newValue);
  }

  saveMobilePhone(verificationMode, newValue) {
    AccountActionCreators.saveEmailOrPhone(verificationMode, newValue);
  }

  checkVerificationCode(verificationMode, verificationCode) {
    AccountActionCreators.checkVerificationCode(verificationMode, verificationCode);
  }

  requestVerificationCode(verificationMode) {
    AccountActionCreators.sendNewVerificationCode(verificationMode);
  }

  getTranslationForFormField(fieldname) {
    if (fieldname === 'lastname') {
      return _('common:formValidationMessage.lastName');
    } else if (fieldname === 'companyname') {
      return _('common:formValidationMessage.companyName');
    } else if (fieldname === 'street') {
      return _('common:formValidationMessage.street');
    } else if (fieldname === 'zip') {
      return _('common:formValidationMessage.zip');
    } else if (fieldname === 'city') {
      return _('common:formValidationMessage.city');
    } else if (fieldname === 'country') {
      return _('common:formValidationMessage.country');
    }

    return '';
  }

  getAccountBirthdate() {
    // eslint-disable-next-line react/prop-types
    const [{ lang }] = this.props.appState;

    return getFormattedDate(this.state.storeData.accountBirthdate, lang, false, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    });
  }

  userPrivileged() {
    return hasRole(SecurityRole.VX_USER_MASTER);
  }

  hasInputError(inputName) {
    return typeof this.state.displayErrorInputs[inputName] !== 'undefined';
  }

  onValid = () => {
    this.setState({
      isFormValid: true,
      // eslint-disable-next-line react/no-string-refs
      displayErrorInputs: this.refs.addressForm.getDisplayErrorInputs(this.state.errorInputNames),
    });
  };

  onInvalid = () => {
    this.setState({
      isFormValid: false,
      // eslint-disable-next-line react/no-string-refs
      displayErrorInputs: this.refs.addressForm.getDisplayErrorInputs(this.state.errorInputNames),
    });
  };

  getFixedPhoneErrorMessage = () => {
    let errMess = null;
    if (this.state.storeData.fixedPhone.errorMessage) {
      if (this.state.storeData.fixedPhone.errorMessage === 'pin_already_requested') {
        errMess = _('account:error.message.' + this.state.storeData.fixedPhone.errorMessage, {
          sprintf: [this.state.storeData.fixedPhone.minutesUntilNextVerification],
        });
      } else {
        errMess = _('account:error.message.' + this.state.storeData.fixedPhone.errorMessage);
      }
    }
    return errMess;
  };

  getMobilePhoneErrorMessage = () => {
    let errMess = null;
    if (this.state.storeData.mobilePhone.errorMessage) {
      if (this.state.storeData.mobilePhone.errorMessage === 'pin_already_requested') {
        errMess = _('account:error.message.' + this.state.storeData.mobilePhone.errorMessage, {
          sprintf: [this.state.storeData.mobilePhone.minutesUntilNextVerification],
        });
      } else {
        errMess = _('account:error.message.' + this.state.storeData.mobilePhone.errorMessage);
      }
    }
    return errMess;
  };

  render() {
    return (
      <Fragment>
        <div className="grid__row">
          <div className="grid__column grid__box min-width--0">
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'account:personalData.pageTitle'} />
              </div>
            </header>

            {this.userPrivileged() && (
              <Fragment>
                <div className="grid__box__item">
                  <div className="grid__box__item__content">
                    <div className="warning-text">
                      <div className="warning-text__column">
                        <div className="title">
                          <T _={'common:text.privacy.title'} />:
                        </div>
                        <Markdown
                          className="subtitle"
                          source={_('common:text.privacy.description')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid__box__item">
                  <Form
                    className="grid__box__item__content account-personaldata__formwidth"
                    // eslint-disable-next-line react/no-string-refs
                    ref="addressForm"
                    onChange={this.onFormChange}
                    onValid={this.onValid}
                    onInvalid={this.onInvalid}
                    onValidSubmit={this.onValidSubmit}
                    onInvalidSubmit={this.onInvalidSubmit}
                  >
                    {!this.loadingDone && <Spinner />}

                    {/* WARNINGS */}
                    {!this.state.storeData.isFormEditable &&
                      !this.state.storeData.isAccountVerified && (
                        <GroupRow tooltip="none">
                          <Notice
                            icon="icon-warning-sign"
                            text={_('account:personalData.warnings.docsMissnig')}
                          />
                        </GroupRow>
                      )}

                    {/* IF COMPANY */}
                    {this.state.storeData.address.isCompany && (
                      <GroupRow label={_('common:text.companyname')} tooltip="none">
                        <Inputfield
                          required
                          requiredMessage={_('common:formRequiredMessage.companyName')}
                          validations="minLength:2"
                          validationError={_('common:formValidationMessage.companyName')}
                          name="companyname"
                          type="text"
                          value={this.state.storeData.address.companyname}
                          placeholder={_('common:text.firstname')}
                          readOnly={!this.state.storeData.isNameEditable}
                          maxLength={50}
                        />
                      </GroupRow>
                    )}

                    {/* IF NOT COMPANY */}
                    {!this.state.storeData.address.isCompany && (
                      <Fragment>
                        <ClassNames>
                          {({ css }) => (
                            <GroupRow
                              label={_('common:text.birthdate')}
                              labelClass={css({ padding: 8 })}
                            >
                              <Inputfield
                                required
                                requiredMessage={_('common:formRequiredMessage.birthdate')}
                                validations="minLength:2"
                                validationError={_('common:formValidationMessage.birthdate')}
                                name="accountBirthdate"
                                type="text"
                                value={this.getAccountBirthdate()}
                                placeholder={_('common:text.birthdate')}
                                readOnly
                              />
                            </GroupRow>
                          )}
                        </ClassNames>

                        <ClassNames>
                          {({ css }) => (
                            <GroupRow
                              label={_('common:text.firstname')}
                              labelClass={css({ padding: 8 })}
                              tooltip="none"
                              className={`${this.hasInputError('firstname') ? 'error' : ''}`}
                              errorMessage={this.state.displayErrorInputs['firstname']}
                            >
                              <Inputfield
                                required
                                requiredMessage={_('common:formRequiredMessage.firstName')}
                                validations="minLength:2"
                                validationError={_('common:formValidationMessage.firstName')}
                                name="firstname"
                                type="text"
                                value={this.state.storeData.address.firstname}
                                placeholder={_('common:text.firstname')}
                                readOnly={!this.state.storeData.isNameEditable}
                                maxLength={50}
                              />
                            </GroupRow>
                          )}
                        </ClassNames>

                        <ClassNames>
                          {({ css }) => (
                            <GroupRow
                              label={_('common:text.lastname')}
                              labelClass={css({ padding: 8 })}
                              tooltip="none"
                              className={`${this.hasInputError('lastname') ? 'error' : ''}`}
                              errorMessage={this.state.displayErrorInputs['lastname']}
                            >
                              <Inputfield
                                required
                                requiredMessage={_('common:formRequiredMessage.lastName')}
                                validations="minLength:2"
                                validationError={_('common:formValidationMessage.lastName')}
                                name="lastname"
                                type="text"
                                value={this.state.storeData.address.lastname}
                                placeholder={_('common:text.lastname')}
                                readOnly={!this.state.storeData.isNameEditable}
                                maxLength={50}
                              />
                            </GroupRow>
                          )}
                        </ClassNames>
                      </Fragment>
                    )}

                    <GroupColumn
                      label={`${_('common:text.street')}, ${_('common:text.streetnbr')}:`}
                      tooltip="none"
                      className={`${
                        this.hasInputError('street') || this.hasInputError('streetnbr')
                          ? 'error'
                          : ''
                      }`}
                      errorMessage={
                        this.state.displayErrorInputs['street'] ||
                        this.state.displayErrorInputs['streetnbr']
                      }
                    >
                      <Inputfield
                        required
                        requiredMessage={_('common:formRequiredMessage.street')}
                        validations="minLength:3"
                        validationError={_('common:formValidationMessage.street')}
                        name="street"
                        type="text"
                        value={this.state.storeData.address.street}
                        placeholder={_('common:text.street')}
                        readOnly={!this.state.storeData.isAddressEditable}
                        maxLength={100}
                      />
                      <Inputfield
                        class="input--small"
                        name="streetnbr"
                        type="text"
                        value={this.state.storeData.address.streetnbr}
                        placeholder={_('common:text.streetnbr')}
                        readOnly={!this.state.storeData.isAddressEditable}
                        maxLength={15}
                      />
                    </GroupColumn>

                    <GroupColumn
                      label={`${_('common:text.zipcode')}, ${_('common:text.city')}:`}
                      tooltip="none"
                      className={`${
                        this.hasInputError('zip') || this.hasInputError('city') ? 'error' : ''
                      }`}
                      errorMessage={
                        this.state.displayErrorInputs['zip'] ||
                        this.state.displayErrorInputs['city']
                      }
                    >
                      <Inputfield
                        required
                        requiredMessage={_('common:formRequiredMessage.zipCode')}
                        validations="minLength:3"
                        validationError={_('common:formValidationMessage.zipCode')}
                        class="input--small"
                        name="zip"
                        type="text"
                        value={this.state.storeData.address.zip}
                        placeholder={_('common:text.zipcode')}
                        readOnly={!this.state.storeData.isAddressEditable}
                        maxLength={15}
                      />
                      <Inputfield
                        required
                        requiredMessage={_('common:formRequiredMessage.city')}
                        validations="minLength:3"
                        validationError={_('common:formValidationMessage.city')}
                        name="city"
                        type="text"
                        value={this.state.storeData.address.city}
                        placeholder={_('common:text.city')}
                        readOnly={!this.state.storeData.isAddressEditable}
                        maxLength={100}
                      />
                    </GroupColumn>

                    <ClassNames>
                      {({ css }) => (
                        <GroupRow
                          label={`${_('common:text.country')}:`}
                          labelClass={css({ padding: 8 })}
                          tooltip="none"
                          className={this.hasInputError('country') ? 'error' : ''}
                          errorMessage={this.state.displayErrorInputs['country']}
                        >
                          {this.state.storeData.isAddressEditable && (
                            <Dropdown
                              required
                              requiredMessage={_('common:formRequiredMessage.country')}
                              name="country"
                              value={this.state.storeData.address.country}
                              forceTranslate={true}
                              options={this.state.storeData.countries}
                              placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                            />
                          )}

                          {!this.state.storeData.isAddressEditable && (
                            <Inputfield
                              name="country"
                              type="text"
                              value={_(
                                'enum:common.country.' + this.state.storeData.address.country
                              )}
                              readOnly
                            />
                          )}
                        </GroupRow>
                      )}
                    </ClassNames>

                    <ClassNames>
                      {({ css }) => (
                        <GroupRow
                          label={`${_('common:text.contactLanguage')}:`}
                          labelClass={css({ padding: 8 })}
                          tooltip="none"
                          className={this.hasInputError('language') ? 'error' : ''}
                          errorMessage={this.state.displayErrorInputs['language']}
                        >
                          <Dropdown
                            required
                            requiredMessage={_('common:formRequiredMessage.language')}
                            name="language"
                            value={this.state.storeData.language}
                            forceTranslate={true}
                            options={this.state.storeData.languages}
                            placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                          />
                        </GroupRow>
                      )}
                    </ClassNames>

                    <ButtonGroup position="right">
                      <Button label={_('common:button.save')} />
                    </ButtonGroup>
                  </Form>
                </div>

                {/* EMAIL AND PHONE NUMBERS */}
                <div className="grid__box__item">
                  <Formsy.Form
                    className="grid__box__item__content account-personaldata__formwidth"
                    // eslint-disable-next-line react/no-string-refs
                    ref="emailPhoneForm"
                  >
                    {/* EMAIL VERIFICATION */}
                    {this.state.storeData.isEmailChanged && (
                      <GroupRow label={_('common:text.actualEmail')} tooltip="none">
                        <Inputfield
                          name="actualEmail"
                          type="text"
                          value={this.state.storeData.hostActualEmailAddress}
                          readOnly
                        />
                      </GroupRow>
                    )}

                    <GroupColumn
                      label={`${_(
                        this.state.storeData.isEmailChanged
                          ? 'common:text.newEmail'
                          : 'common:text.email'
                      )}:`}
                      tooltip="none"
                      className={
                        this.state.storeData.email.errorMessage &&
                        this.state.storeData.email.errorMessage === 'success'
                          ? 'error'
                          : ''
                      }
                      errorMessage={this.displayEmailErrorMessage()}
                    >
                      <Inputfield
                        // eslint-disable-next-line react/no-string-refs
                        ref="emailAddress"
                        name="email"
                        value={this.state.storeData.email.address}
                        type="text"
                        placeholder={_('common:text.email')}
                        errorMessage={
                          this.state.storeData.isEmailChanged
                            ? _('account:personalData.warnings.verifyEmail')
                            : null
                        }
                      />
                      <Button
                        label={_(
                          this.state.storeData.isEmailChanged
                            ? 'register:confirmationPage.changeEmail.button.label.submit'
                            : 'register:stepCompleteRegistration.action.changeEmailText'
                        )}
                        onClick={() => this.saveValue('emailAddressVerification')}
                      />
                    </GroupColumn>

                    {/* FIXED PHONE NUMBER VERIFICATION */}
                    <GroupColumn label={`${_('common:text.phone')}:`} tooltip="none">
                      <Inputfield
                        // eslint-disable-next-line react/no-string-refs
                        ref="fixedPhoneNumber"
                        name="fixedPhoneNnumber"
                        value={this.state.storeData.fixedPhone.number}
                        placeholder={_('common:text.phone')}
                        type="text"
                        readOnly
                      />

                      <Button
                        label={
                          this.state.storeData.fixedPhone.verificationStatus === 'check'
                            ? _('common:button.verify')
                            : _('common:button.edit')
                        }
                        onClick={() => this.modalTelephoneConfirmation.open()}
                      />
                    </GroupColumn>

                    {/* MOBILE PHONE NUMBER VERIFICATION */}
                    <GroupColumn label={`${_('common:text.mobilephone')}:`} tooltip="none">
                      <Inputfield
                        // eslint-disable-next-line react/no-string-refs
                        ref="mobilePhoneNumber"
                        name="mobilePhoneNumber"
                        value={this.state.storeData.mobilePhone.number}
                        placeholder={_('common:text.mobilephone')}
                        type="text"
                        readOnly
                      />

                      <Button
                        label={
                          this.state.storeData.mobilePhone.verificationStatus === 'check'
                            ? _('common:button.verify')
                            : _('common:button.edit')
                        }
                        onClick={() => this.modalMobilephoneConfirmation.open()}
                      />
                    </GroupColumn>
                  </Formsy.Form>
                </div>

                <div className="grid__box__item">
                  <Formsy.Form
                    className="grid__box__item__content account-personaldata__formwidth"
                    // eslint-disable-next-line react/no-string-refs
                    ref="contactForm"
                    onSubmit={this.onContactFormSubmit}
                  >
                    <ClassNames>
                      {({ css }) => (
                        <GroupRow label={_('common:text.skype')} labelClass={css({ padding: 8 })}>
                          <Inputfield
                            name="skype"
                            value={this.state.storeData.contact.skype}
                            placeholder={_('common:text.skype')}
                            type="text"
                          />
                        </GroupRow>
                      )}
                    </ClassNames>

                    <GroupColumnNew label={_('common:text.whatsapp')} tooltip="none">
                      <section className="min">
                        <Dropdown
                          name="whatsappCountry"
                          value={this.state.storeData.contact.whatsappCountry}
                          options={this.state.storeData.whatsappCountries}
                          placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                        />
                      </section>
                      <section>
                        <Inputfield
                          validations={{ matchRegexp: /^[1-9]{1}[0-9]{5,15}$/ }}
                          validationError={_('common:formValidationMessage.whatsapp')}
                          name="whatsappNumber"
                          placeholder={_('common:text.whatsapp')}
                          value={this.state.storeData.contact.whatsappNumber}
                          type="text"
                        />
                      </section>
                    </GroupColumnNew>

                    <ClassNames>
                      {({ css }) => (
                        <GroupRow
                          label={_('common:text.facebook')}
                          labelClass={css({ padding: 8 })}
                        >
                          <Inputfield
                            name="facebook"
                            value={this.state.storeData.contact.facebook}
                            placeholder={_('common:text.facebook')}
                            type="text"
                          />
                        </GroupRow>
                      )}
                    </ClassNames>

                    <ClassNames>
                      {({ css }) => (
                        <GroupRow label="Instagram" labelClass={css({ padding: 8 })}>
                          <Inputfield
                            name="instagram"
                            value={this.state.storeData.contact.instagram}
                            placeholder="Instagram"
                            type="text"
                          />
                        </GroupRow>
                      )}
                    </ClassNames>

                    <ClassNames>
                      {({ css }) => (
                        <GroupRow label="Youtube" labelClass={css({ padding: 8 })}>
                          <Inputfield
                            name="youtube"
                            value={this.state.storeData.contact.youtube}
                            placeholder="Youtube"
                            type="text"
                          />
                        </GroupRow>
                      )}
                    </ClassNames>

                    <ClassNames>
                      {({ css }) => (
                        <GroupRow label="Twitter" labelClass={css({ padding: 8 })}>
                          <Inputfield
                            name="twitter"
                            value={this.state.storeData.contact.twitter}
                            placeholder="Twitter"
                            type="text"
                          />
                        </GroupRow>
                      )}
                    </ClassNames>

                    <ButtonGroup position="right">
                      <Button label={_('common:button.save')} type="submit" />
                    </ButtonGroup>
                  </Formsy.Form>
                </div>
              </Fragment>
            )}

            {!this.userPrivileged() && <EmptyContent title={_('common:forbidden.text')} />}
          </div>
        </div>

        <ModalPhoneConfirmation
          ref={(r) => {
            this.modalTelephoneConfirmation = r;
          }}
          currentNumber={this.state.storeData.fixedPhone.number}
          verificationMode={this.state.storeData.fixedPhone.verificationMode}
          requestVerificationCode={this.requestVerificationCode}
          checkVerificationCode={this.checkVerificationCode}
          saveNumber={this.saveMobilePhone}
          errorMessage={this.getFixedPhoneErrorMessage()}
          verificationStatus={this.state.storeData.fixedPhone.verificationStatus}
          startScreen={
            this.state.storeData.fixedPhone.verificationStatus === 'check'
              ? 'verify-screen'
              : 'change-screen'
          }
        />

        {/* gute zeichen? */}
        <ModalPhoneConfirmation
          ref={(r) => {
            this.modalMobilephoneConfirmation = r;
          }}
          currentNumber={this.state.storeData.mobilePhone.number}
          verificationMode={this.state.storeData.mobilePhone.verificationMode}
          requestVerificationCode={this.requestVerificationCode}
          checkVerificationCode={this.checkVerificationCode}
          saveNumber={this.saveMobilePhone}
          errorMessage={this.getMobilePhoneErrorMessage()}
          verificationStatus={this.state.storeData.mobilePhone.verificationStatus}
          startScreen={
            this.state.storeData.mobilePhone.verificationStatus === 'check'
              ? 'verify-screen'
              : 'change-screen'
          }
        />
      </Fragment>
    );
  }

  displayEmailErrorMessage() {
    let translationKey = '';

    if (this.state.storeData.email.errorMessage) {
      this.state.storeData.email.errorMessage === 'invalidFormat'
        ? (translationKey = 'invalidFormat')
        : null;
      this.state.storeData.email.errorMessage === 'chooseAnother'
        ? (translationKey = 'chooseAnother')
        : null;
      this.state.storeData.email.errorMessage === 'invalidDataType'
        ? (translationKey = 'invalidDataType')
        : null;
    }

    return translationKey ? _(`validation:email.${translationKey}`) : '';
  }
}

export default withAppState(PersonalData);
