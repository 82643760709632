import React, { FC, useCallback, useState } from 'react';
import arrowLeft from './assets-banner/arrow-left.png';
import arrowRight from './assets-banner/arrow-right.png';
import backgroundWave from './assets-banner/background-wave.png';
import fasterVxmodels from './assets-banner/faster-vxmodels.gif';
import mobileOptimiert from './assets-banner/mobile-optimiert-icon.gif';
import previewBetaLeft from './assets-banner/preview-beta-left.png';
import previewBetaRight from './assets-banner/preview-beta-right.png';
import betaLogo from './assets-banner/Beta-Logo.png';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import NewButton from '../../atoms/Button/NewButton';
import { _ } from '../../util/translate';

const BetaBanner: FC = () => {
  // font size depends on container size
  const [scale, setScale] = useState(1.0);
  const ref = useCallback((node) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      setScale(node.getBoundingClientRect().width / 1400.0);
      console.log(scale);
    });
    resizeObserver.observe(node);
  }, []);

  return (
    <a href="https://beta.vxmodels.com/account">
      <Container maxWidth={false}>
        <Grid container spacing={2} alignItems="stretch" ref={ref}>
          <Box
            width="100%"
            borderRadius="5px"
            marginTop="10px"
            marginBottom="30px"
            position="relative"
            style={{
              aspectRatio: '1400/150',
              backgroundColor: 'white',
              backgroundImage: `url(${backgroundWave})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <img
              src={previewBetaLeft}
              style={{
                position: 'absolute',
                top: '10%',
                height: '90%',
                left: '5.5%',
              }}
            />
            <img
              src={fasterVxmodels}
              style={{
                position: 'absolute',
                top: '56%',
                width: '3.5%',
                left: '17.5%',
              }}
            />
            <Typography
              style={{
                position: 'absolute',
                top: '65%',
                left: '21%',
                fontSize: 18 * scale + 'px',
              }}
            >
              {_('account:betabanner.faster')}
            </Typography>
            <img
              src={arrowLeft}
              style={{
                position: 'absolute',
                top: '9%',
                left: '26%',
                width: '5.5%',
              }}
            />
            <img
              src={betaLogo}
              style={{
                position: 'absolute',
                top: '29%',
                left: '32%',
                width: '22%',
              }}
            />
            <img
              src={arrowRight}
              style={{
                position: 'absolute',
                top: '23%',
                left: '55%',
                width: '7%',
              }}
            />
            <img
              src={mobileOptimiert}
              style={{
                position: 'absolute',
                top: '20%',
                left: '55%',
                width: '3%',
              }}
            />
            <Typography
              style={{
                position: 'absolute',
                top: '25%',
                left: '58%',
                fontSize: 18 * scale + 'px',
              }}
            >
              {_('account:betabanner.optimized')}
            </Typography>
            <img
              src={previewBetaRight}
              style={{
                position: 'absolute',
                top: '0',
                height: '100%',
                left: '69%',
              }}
            />
            <NewButton
              color="beta"
              style={{
                position: 'absolute',
                right: '4%',
                top: '38%',
                height: 38 * scale + 'px',
                fontSize: 16 * scale + 'px',
                padding: '0 ' + 18 * scale + 'px',
              }}
              href={'https://beta.vxmodels.com/account'}
            >
              {_('account:betabanner.switch')}
            </NewButton>
          </Box>
        </Grid>
      </Container>
    </a>
  );
};

export default BetaBanner;
