import gql from 'graphql-tag';

export const GET_TUS_AUTHTOKEN = gql`
  query GET_TUS_AUTHTOKEN {
    auth {
      id
      accessToken
      refreshToken
    }
  }
`;
