import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import { AccountActionCreators } from '../../../stores/Account/AccountActionCreators';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';

import { Button } from '../../../atoms';
import { Tooltip, Spinner } from '../../../components';
import { RED } from '../../../camtool-styles';
import { _ } from '../../../util/translate';
import { hasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';

const StyledLabel = styled.label`
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const passwordField = css`
  border: 1px solid #dcdcde;
  padding: 8px;
`;

const AccountPasswords = () => {
  const isMaster = hasRole(SecurityRole.VX_USER_MASTER);
  const handleSubmit = (values, formikBag) => {
    AccountActionCreators.saveNewPassword(values, (res) => {
      if (res.savingResult.savingStatus === 'fail') {
        formikBag.setErrors({ [res.savingResult.invalidField]: _(res.savingResult.message) });
        AlertsStore.add({ type: 'error', message: _(res.savingResult.message) });
      } else if (res.savingResult.savingStatus === 'ok') {
        AlertsStore.add({ type: 'info', message: _(res.savingResult.message) });
        formikBag.resetForm();
      }
    });

    formikBag.setSubmitting(false);
  };

  return (
    <div className="grid__row">
      <div className="grid__column grid__box min-width--0">
        <div className="grid__box__header">
          <div className="grid__box__header__title">{_('account:passwords.pageTitle')}</div>
        </div>

        <div className="grid__box__row">
          <div className="grid__box__column grid__box__sub">
            <div className="grid__box__sub__header">
              <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                {_('account:passwords.box.normalPass.title')}
              </div>
              <Tooltip
                position="right"
                text={_('account:passwords.single.tooltip')}
                className="grid__box__sub__header__item"
              />
            </div>

            <div className="grid__box__sub__item spinner-container">
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  oldNormalPass: '',
                  newNormalPass1: '',
                  newNormalPass2: '',
                  passType: 'normal'
                }} // prettier-ignore
                render={({ isSubmitting, dirty, errors, touched }) => {
                  return (
                    <Form className="grid__box__sub__item__content">
                      {isSubmitting && <Spinner />}

                      <StyledLabel>
                        <p css={{ flex: '0 1 168px' }}>
                          {_('account:passwords.fields.oldPassword')}
                        </p>
                        <div css={{ flexDirection: 'column', flex: '1 0 228px' }}>
                          <Field name="oldNormalPass" type="password" css={passwordField} />
                          <ErrorMessage
                            name="oldNormalPass"
                            component="span"
                            css={{ color: RED, padding: '0 2px' }}
                          />
                        </div>
                      </StyledLabel>

                      <StyledLabel>
                        <p css={{ flex: '0 1 168px' }}>
                          {_('account:passwords.fields.newPassword')}
                        </p>
                        <div css={{ flexDirection: 'column', flex: '1 0 228px' }}>
                          <Field name="newNormalPass1" type="password" css={passwordField} />
                          <ErrorMessage
                            name="newNormalPass1"
                            component="span"
                            css={{ color: RED, padding: '0 2px' }}
                          />
                        </div>
                      </StyledLabel>

                      <StyledLabel>
                        <p css={{ flex: '0 1 168px' }}>
                          {_('account:passwords.fields.repeatPassword')}
                        </p>
                        <div css={{ flexDirection: 'column', flex: '1 0 228px' }}>
                          <Field name="newNormalPass2" type="password" css={passwordField} />
                          <ErrorMessage
                            name="newNormalPass2"
                            component="span"
                            css={{ color: RED, padding: '0 2px' }}
                          />
                        </div>
                      </StyledLabel>

                      <Button
                        label={_('common:button.save')}
                        theme={(() => {
                          if (!dirty || !touched || Object.keys(errors).length > 0) return 'gray';
                          else return 'blue';
                        })()}
                        disabled={
                          isSubmitting || !dirty || !touched || Object.keys(errors).length > 0
                        }
                        type="submit"
                        css={{ alignSelf: 'flex-end' }}
                      />
                    </Form>
                  );
                }}
              />
            </div>
          </div>

          <div className="grid__box__column grid__box__sub min-width--0">
            {isMaster && (
              <>
                <div className="grid__box__sub__header">
                  <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                    {_('account:passwords.box.masterPass.title')}
                  </div>
                  <Tooltip
                    position="bottom"
                    text={_('account:passwords.master.tooltip')}
                    className="grid__box__sub__header__item"
                  />
                </div>

                <div className="grid__box__sub__item spinner-container">
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                      oldMasterPass: '',
                      newMasterPass1: '',
                      newMasterPass2: '',
                      passType: 'master'
                    }} // prettier-ignore
                    render={({ isSubmitting, dirty, errors, touched }) => {
                      return (
                        <Form className="grid__box__sub__item__content">
                          {isSubmitting && <Spinner />}

                          <StyledLabel>
                            <p css={{ flex: '0 1 168px' }}>
                              {_('account:passwords.fields.oldPassword')}
                            </p>
                            <div css={{ flexDirection: 'column', flex: '1 0 228px' }}>
                              <Field name="oldMasterPass" type="password" css={passwordField} />
                              <ErrorMessage
                                name="oldMasterPass"
                                component="span"
                                css={{ color: RED, padding: '0 2px' }}
                              />
                            </div>
                          </StyledLabel>

                          <StyledLabel>
                            <p css={{ flex: '0 1 168px' }}>
                              {_('account:passwords.fields.newPassword')}
                            </p>
                            <div css={{ flexDirection: 'column', flex: '1 0 228px' }}>
                              <Field name="newMasterPass1" type="password" css={passwordField} />
                              <ErrorMessage
                                name="newMasterPass1"
                                component="span"
                                css={{ color: RED, padding: '0 2px' }}
                              />
                            </div>
                          </StyledLabel>

                          <StyledLabel>
                            <p css={{ flex: '0 1 168px' }}>
                              {_('account:passwords.fields.repeatPassword')}
                            </p>
                            <div css={{ flexDirection: 'column', flex: '1 0 228px' }}>
                              <Field name="newMasterPass2" type="password" css={passwordField} />
                              <ErrorMessage
                                name="newMasterPass2"
                                component="span"
                                css={{ color: RED, padding: '0 2px' }}
                              />
                            </div>
                          </StyledLabel>

                          <Button
                            label={_('common:button.save')}
                            theme={(() => {
                              if (!dirty || !touched || Object.keys(errors).length > 0)
                                return 'gray';
                              else return 'blue';
                            })()}
                            disabled={
                              isSubmitting || !dirty || !touched || Object.keys(errors).length > 0
                            }
                            type="submit"
                            css={{ alignSelf: 'flex-end' }}
                          />
                        </Form>
                      );
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPasswords;
